@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import url('../node_modules/bootstrap/dist/css/bootstrap.css');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  